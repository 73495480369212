<template>
  <div>
    <!-- tabs -->
    <v-tabs v-model="tab">
      <v-tab>Timeline</v-tab>
      <v-tab>Calendar</v-tab>
    </v-tabs>

    <!-- tabs' items -->
    <v-tabs-items v-model="tab">
      <!-- task timeline -->
      <v-tab-item>
        <task-timeline :task="task"></task-timeline>
      </v-tab-item>

      <!-- task calendar-->
      <v-tab-item>
        <base-calendar :content="events"></base-calendar>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { getTaskCalendarEvents } from "@/services/inbox/taskService";

export default {
  name: "TaskChart",
  components: {
    TaskTimeline: () => import("@/components/task/TaskTimeline.vue"),
    BaseCalendar: () => import("@/components/shared/base/BaseCalendar")
  },
  data() {
    return {
      tab: 0
    };
  },
  props: {
    task: undefined
  },
  computed: {
    events() {
      return getTaskCalendarEvents(this.task) ?? [];
    }
  }
};
</script>
